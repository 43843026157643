"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBenchmarkInterval = exports.getBenchmarkDateRange = void 0;
const Benchmark_1 = require("../constants/Benchmark");
const Moment = require("moment");
exports.getBenchmarkDateRange = (benchmarkDuration, format = 'YYYY-MM-DD', order = "ASC") => {
    const dateArray = [];
    const currentDate = Moment();
    const NumberOfDays = {
        [Benchmark_1.BenchmarkDuration.LAST_7_DAYS]: 7,
        [Benchmark_1.BenchmarkDuration.LAST_30_DAYS]: 30,
        [Benchmark_1.BenchmarkDuration.LAST_90_DAYS]: 90,
        [Benchmark_1.BenchmarkDuration.LAST_180_DAYS]: 180,
    };
    switch (benchmarkDuration) {
        case Benchmark_1.BenchmarkDuration.TODAY_LIVE:
            dateArray.push(currentDate.format(format));
            break;
        case Benchmark_1.BenchmarkDuration.LAST_7_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_30_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_90_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_180_DAYS:
            const daysCount = NumberOfDays[benchmarkDuration];
            for (let i = 0; i < daysCount; i++) {
                dateArray.push(currentDate.clone().subtract(i, 'days').format(format));
            }
            break;
        default:
            throw new Error("Unsupported benchmark duration");
    }
    return order === "DESC" ? dateArray : dateArray.reverse();
};
exports.getBenchmarkInterval = (duration) => {
    let from = Moment().startOf('day').format('YYYY-MM-DD 00:00:00');
    let to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
    switch (duration) {
        case Benchmark_1.BenchmarkDuration.TODAY_LIVE:
            from = Moment().startOf('day').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_7_DAYS:
            from = Moment().startOf('day').subtract(7, "days").format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_30_DAYS:
            from = Moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_90_DAYS:
            from = Moment().startOf('day').subtract(90, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_180_DAYS:
            from = Moment().startOf('day').subtract(180, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
    }
    return {
        from,
        to
    };
};
