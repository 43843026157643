import React from "react";
import Translation from "../i18n";
import {connect} from "react-redux";
import {IAuthObject} from "../Interfaces/Redux";
import {IUser} from "../Interfaces/Common";
import {
  BenchmarkDuration,
  BenchmarkMetrics,
  SiteBenchmarkMetric,
  SiteBenchmarkMetrics
} from "connectel-shared/constants/Benchmark";
import {getBenchmarkData} from "../Store";
import {getBenchmarkDateRange} from "connectel-shared/utils/date";
import moment from "moment";
import { BenchmarkQueryType } from "connectel-shared/interfaces/Benchmark";

interface IProps {
  auth: IAuthObject;
  users: Array<IUser>;
}

interface IState {
  benchmarkData: SiteBenchmarkMetrics;
  benchmarkAlias: string;
}

class Billing extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      benchmarkData: [],
      benchmarkAlias: BenchmarkMetrics[0].alias
    };
  }

  async componentDidMount(): Promise<void> {
    const benchmarkData = await getBenchmarkData(this.state.benchmarkAlias);
    this.setState({
      benchmarkData: benchmarkData?.data
    });
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        {this.getHeader()}
        {this.getTable()}
      </React.Fragment>
    );
  }

  getHeader(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <h1>{t("CUSTOMER_OVERVIEW")}</h1>
          </React.Fragment>
        )}
      </Translation>
    );
  }

  getTable(): React.ReactNode {
    return (
      <React.Fragment>
        <select
          onChange={(event) => this.handleMetricChange(event.target.value)}
          style={{position: "absolute", padding: "10px", right: "30px"}}
        >
          {BenchmarkMetrics.map((metric) => {
            return <option value={metric.alias}>{metric.title}</option>;
          })}
        </select>
        <h1 style={{textAlign: "center"}}>Benchmark Portal Overview</h1>
        <div className="table-container">
          <table className="list users">
            <thead>
              <tr>
                <th>Name</th>
                {getBenchmarkDateRange(BenchmarkDuration.LAST_30_DAYS).map(
                  (date) => {
                    return <th>{moment(date).format("D MMM")}</th>;
                  }
                )}
                {this.state.benchmarkAlias === "VB_booking_success" && <th>Summary</th>}
              </tr>
            </thead>
            <tbody>
              {this.state.benchmarkData?.map((data) => {
                const totalCalls = data.values?.map(value => value?.metadata?.totalCalls ? parseInt(value?.metadata?.totalCalls as string) : 0)?.reduce((x, y) => x+y, 0)

                return (
                  <tr>
                    <td>{data.site_name}</td>
                    {data.values.map((timeData) => {
                      const backgroundColor = this.getColumnColor(
                        data,
                        timeData
                      );

                      return (
                        <td
                          style={{backgroundColor}}
                          title={this.getMetricColumnTitle(data.metricAlias, timeData)}
                        >
                          {timeData?.metadata?.totalCalls ? `${timeData.value}(${timeData?.metadata?.totalCalls})` : (timeData.value || "-" )  }
                        </td>
                      );
                    })}
                    {this.state.benchmarkAlias === "VB_booking_success" && <td>{totalCalls}</td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  private async handleMetricChange(metricAlias: string) {
    const benchmarkData = await getBenchmarkData(metricAlias);
    this.setState({
      benchmarkAlias: metricAlias,
      benchmarkData: benchmarkData.data
    });
  }

  private getColumnColor(
    data: SiteBenchmarkMetric,
    timeData: {
      date: string;
      value: number;
      average: number;
    }
  ): string {
    let backgroundColor: string = null;
    switch (data.queryType) {
      case BenchmarkQueryType.Min:
        backgroundColor =
          timeData.value <= timeData.average
            ? "rgb(174, 246, 173)"
            : "rgb(245, 206, 204)";
        backgroundColor = !timeData.value ? "white" : backgroundColor;
        break;
      case BenchmarkQueryType.Max:
        backgroundColor =
          timeData.value >= timeData.average
            ? "rgb(174, 246, 173)"
            : "rgb(245, 206, 204)";
        backgroundColor = !timeData.value ? "white" : backgroundColor;
    }
    return backgroundColor;
  }

  private getMetricColumnTitle(metricAlias: string, timeData): string {
    switch(metricAlias) {
      case "VB_booking_success":
        const average = timeData.average.toString();
        const totalCalls = timeData.metadata?.totalCalls || ""
        return timeData.metadata?.totalCalls ? `${average}(${totalCalls})` : average;
      default:
        return timeData.average.toString()
    }
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    users: state.users
  };
}

export default connect(mapStateToProps, {})(Billing);
